<template>
  <div :class="classAttributes" class="mb-3">
    <div class="Card_Addressbook h-100">
        <div class="Card_Addressbook__Body">
            <div class="Logo">
                <img :src="contact.logo.url" :alt="contact.title" />
            </div>
            <div class="Info">
                <h3>{{ contact.title }}</h3>
                <div v-if="contact.phone"><fa-icon icon="fa-solid fa-phone-flip" class="me-2"></fa-icon>{{ contact.phone }}</div>
                <div v-if="contact.email"><fa-icon icon="fa-regular fa-paper-plane" class="me-2"></fa-icon>{{ contact.email }}</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AddressBookCard',
    props: { 
        contact: { type: Object, default: null },
        classAttributes: { type: String, default: 'col-xl-6'}
    },
}
</script>

<style lang="scss" scoped>
.Card_Addressbook {
    display: block;
    background-color: white;
    border-radius: 0rem;
    border: 1px solid white;
    &__Body {
        padding: 1.25rem 1.5rem;
        display: flex;
        flex: 1 1 auto;
        gap: 1rem;
        .Logo {
            flex: 2;
        }
        .Info {
            flex: 7;
            font-size: 0.85rem;
          h3 {
            font-size: 1.25rem;
            font-weight: 600;
          }
        }
    }
}
</style>